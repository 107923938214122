import "./Portfolio.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

function Portfolio() {
  return (
    <>
      <Header />
      <div>hello world</div>
      <Footer />
    </>
  );
}

export default Portfolio;
